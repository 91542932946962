import React from 'react';
import Helmet from 'react-helmet';
import Img from 'gatsby-image';
import styled from 'styled-components';
import Layout from '../components/Layout';
import Content from '../components/Content';

const GalleryContainer = styled.div`
  text-align: center;
  margin: 0 1rem;
  overflow: hidden;
  display: grid;
  grid-template-columns: 1fr 1fr;
`;

const GalleryRow = styled.div`
  margin: 0 0 1rem 0;
  padding: 1rem 0 0 0;
  border-width: 1px 0 0 0;
  border-style: solid;
  border-collapse: #ffffff;
`;

class GalleryPageTemplate extends React.Component {
  render() {
    const { pageContext } = this.props;

    return (
      <Layout>
        <Helmet>
          <title>Legacy of Steel</title>
        </Helmet>
        <Content>
          <h1> {pageContext.slug}</h1>
          <GalleryContainer>
            {pageContext.nodes.map(node => {
              if (!node?.childImageSharp?.fixed) {
                return (
                  <GalleryRow key={node.id}>
                    <a href={node.publicURL}>
                      <div>{node.publicUrl}</div>
                    </a>
                  </GalleryRow>
                );
              }
              return (
                <GalleryRow key={node.id}>
                  <a href={node.publicURL}>
                    <Img fixed={node?.childImageSharp?.fixed}></Img>
                  </a>
                </GalleryRow>
              );
            })}
          </GalleryContainer>
        </Content>
      </Layout>
    );
  }
}

export default GalleryPageTemplate;
